<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-card>
      <div class="row">
        <v-container class="pt-0">
          <v-form v-model="isFormValid">
            <v-container>
              <v-row v-if="apps.length > 1">
                <v-col class="py-0" cols="6">
                  <v-select
                    v-model="appsSelected"
                    label="Aplicaciones móviles"
                    :items="apps"
                    item-text="value"
                    item-value="id"
                    dense
                    outlined
                    clearable
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6" class="py-0">
                  <v-select
                    v-model="tipoDeOperacion"
                    label="Tipo de Operación"
                    :items="operaciones"
                    item-text="nombre"
                    item-value="codigo"
                    dense
                    outlined
                    :rules="rules.required"
                    :disabled="emailId != null"
                  >
                  </v-select>
                </v-col>
                <v-col cols="6" sm="6" md="6" class="py-0">
                  <v-select
                    v-model="servidor"
                    label="Servidor"
                    :items="servidores"
                    item-text="value"
                    item-value="id"
                    dense
                    outlined
                    :rules="rules.required"
                  >
                  </v-select>
                </v-col>
                <v-col cols="4" sm="6" md="4" class="py-0">
                  <v-text-field
                    v-model="nombreRemitente"
                    label="Nombre remitente"
                    dense
                    outlined
                    :rules="rules.required"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" sm="6" md="4" class="py-0">
                  <v-text-field
                    v-model="emailRemitente"
                    label="Email remitente"
                    dense
                    outlined
                    :rules="rules.required.concat(rules.email)"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="4"
                  sm="6"
                  md="4"
                  class="d-flex py-0 justify-content-center"
                >
                  <v-checkbox
                    v-model="activo"
                    id="switch1"
                    class="mt-1"
                  ></v-checkbox>
                  <label for="switch1" class="mt-2">Activo</label>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
            </v-container>
            <div class="col-12 py-0">
              <v-col cols="12" sm="12" md="12" class="pa-0 py-0">
                <v-text-field
                  v-model="asunto"
                  label="Asunto"
                  dense
                  outlined
                  :rules="rules.required"
                >
                </v-text-field>
              </v-col>
              <TextEditor
                ref="text"
                :value.sync="content"
                :variables="variables"
              />
            </div>
            <v-container>
              <!-- <v-row>
                <v-col cols="3" class="py-0">
                  <v-select
                    v-model="variable"
                    label="Variables"
                    :items="variables"
                    item-text="nombre"
                    item-value="var_id"
                    dense
                    outlined
                  >
                  </v-select>
                </v-col>
                <v-btn color="primary" @click="addVariable"> Insertar </v-btn>
              </v-row> -->
              <v-row class="pr-4 pl-4 pt-4" justify="end">
                <v-btn
                  color="primary"
                  :disabled="!isFormValid"
                  @click="saveContent"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-container>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import TextEditor from "@/components/shared/TextEditor.vue";
// import TextEditor from "@/components/textEditor/TextEditor.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "EditEmailApp",
  props: ["emailIdProp", "codigoProp"],
  components: {
    PageHeader,
    TextEditor,
    GoBackBtn
  },
  data() {
    return {
      titleRecuperarContraseña: enums.titles.RECUPERAR_CONTRASEÑA_EMAIL,
      titleAltaUsuario: enums.titles.ALTA_USUARIO_EMAIL,
      titleNuevoFormato: enums.titles.NUEVO_FORMATO_EMAIL,
      title: null,
      content: "",
      routeToGo: "FormatosEmailApp",
      contentChanged: false,
      rules: rules,
      isAltaUsuario: true,
      isFormValid: false,
      nombreRemitente: null,
      emailRemitente: null,
      operaciones: [],
      tipoDeOperacion: this.codigoProp,
      servidores: [],
      variable: null,
      variables: [],
      servidor: null,
      emailId: null,
      asunto: null,
      activo: true,
      cursorLocation: null,
      codigo: null,
      apps: [],
      appsSelected: null
    };
  },
  created() {
    this.getServidores();
    this.getTipoDeOperaciones();
    this.getVariables();
    this.getAppsMoviles();
    this.emailIdProp != null
      ? this.setFormato()
      : (this.title = this.titleNuevoFormato);
  },
  watch: {
    tipoDeOperacion(val) {
      if (val === "APPB_CU") {
        this.codigo = "APPB_CU";
      } else {
        this.codigo = "APPB_RC";
      }
      this.getVariables();
    }
  },
  methods: {
    ...mapActions({
      fetchServidores: "configuracion/fetchServidores",
      fetchVariables: "configuracion/fetchVariables",
      fetchTipoDeOperaciones: "configAppBenef/fetchTipoDeOperaciones",
      getFormatoEmail: "configAppBenef/getFormatoEmail",
      getApps: "appBenef/getApps",
      saveEmail: "configuracion/saveEmail",
      setAlert: "user/setAlert"
    }),
    async getServidores() {
      const response = await this.fetchServidores();
      this.servidores = response;
    },
    async getTipoDeOperaciones() {
      const respo = await this.fetchTipoDeOperaciones();
      this.operaciones = respo;
    },
    async getVariables() {
      if (this.codigoProp) this.codigo = this.codigoProp;
      const resp = await this.fetchVariables(this.codigo);
      this.variables = resp;
    },
    async getAppsMoviles() {
      const resp = await this.getApps();
      this.apps = resp;
    },
    async setFormato() {
      this.codigo = this.codigoProp;
      this.emailId = this.emailIdProp;
      if (this.codigo == "APPB_RC") {
        this.title = this.titleRecuperarContraseña;
      } else {
        this.title = this.titleAltaUsuario;
      }
      const template = await this.getFormatoEmail({
        codigo: this.codigo,
        id: this.emailIdProp
      });
      if (template != undefined) {
        this.emailId = template.emailId;
        this.content = template.cuerpoHtml;
        this.nombreRemitente = template.nombreRemitente;
        this.emailRemitente = template.emailRemitente;
        this.servidor = template.esId;
        this.asunto = template.asunto;
        this.activo = template.activo;
        this.appsSelected = template.appId;
      }
    },
    async saveContent() {
      let res = null;
      if (this.content.trim() === null || this.content.trim() === "") {
        this.setAlert({
          type: "warning",
          message: "El cuerpo del email está vacío"
        });
      } else {
        const data = {
          emailId: this.emailId,
          nombreRemitente: this.nombreRemitente,
          emailRemitente: this.emailRemitente,
          codigo: this.tipoDeOperacion,
          esId: this.servidor,
          asunto: this.asunto,
          activo: this.activo,
          cuerpoHtml: this.content,
          appId: this.appsSelected
        };
        res = await this.saveEmail(data);
      }
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.emailId = res.data;
      }
      this.$router.push({ name: "FormatosEmailApp" });
    },
    async addVariable() {
      this.variable = this.variables.find(
        x => x.nombre === this.variable
      ).variable;

      const Editor = this.$refs.text.$refs.editor.quill;
      let cursorLocation = Editor.selection.savedRange.index;
      Editor.editor.insertText(cursorLocation, this.variable);
    }
  }
};
</script>
<style lang="scss" scoped></style>
